import axios from 'axios';
import qs from 'qs';
const baseURL = 'https://moim-api.chooldo.com/api';

function postApi(data) {
  return axios.post(`${baseURL}/setHostUserInfo`, qs.stringify(data));
}

function getApi(params) {
  return axios.get(`${baseURL}/getGroupMember`, {
    params: params,
  });
}

function postNewMember(data) {
  return axios.post(`${baseURL}/addNewGroupMember`, qs.stringify(data));
}

export { postApi, getApi, postNewMember };
