import Vue from 'vue';
import VueRouter from 'vue-router';
import Survey from '@/views/survey';
import Result from '@/views/result';
import Error404 from '@/views/404';
import Error500 from '@/views/500';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Survey,
    },
    {
      path: '/newMember/:groupId',
      component: Survey,
    },
    {
      path: '/result/:groupId',
      component: Result,
    },
    {
      path: '/500',
      component: Error500,
    },
    {
      path: '/404',
      component: Error404,
    },
    {
      path: '*',
      component: Error404,
    },
  ],
});
