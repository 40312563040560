<template>
  <div class="container">
    <div class="contents">
      <h2 class="title">사주로 보는<br />우리들 케미</h2>
      <p class="desc">
        60가지 다른 기운을 가진 사람들과<br />
        서로의 궁합 확인해 보세요!
      </p>
      <div class="form">
        <div class="item">
          <h3 class="sub-title">
            <img src="../assets/images/image22.svg" alt="" class="emoji" />
            내 이름
          </h3>
          <form-input
            v-model="form.name.value"
            :placeholder="form.name.placeholder"
            :maxlength="form.name.maxlength"
          />
        </div>
        <div class="item">
          <h3 class="sub-title">
            <img src="../assets/images/image23.svg" alt="" class="emoji" />
            생년월일
          </h3>
          <div class="birth-area">
            <span class="form-select">
              <select v-model="form.birthYear" title="년">
                <option value="" selected disabled>년</option>
                <option
                  v-for="item in form.getYear()"
                  :value="item"
                  :key="item"
                >
                  {{ item }}
                </option>
              </select>
            </span>
            <span class="form-select">
              <select v-model="form.birthMonth" title="월">
                <option value="" selected disabled>월</option>
                <option
                  v-for="(item, index) in form.getMonth()"
                  :value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </span>
            <span class="form-select">
              <select v-model="form.birthDay" title="일">
                <option value="" selected disabled>일</option>
                <option
                  v-for="(item, index) in form.getDay()"
                  :value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div class="item">
          <h3 class="sub-title">
            <img src="../assets/images/image24.svg" alt="" class="emoji" />
            시간
          </h3>
          <span class="form-select">
            <select v-model="form.birthTime.chkValue" title="시간" class="agc">
              <option
                v-for="(item, index) in form.birthTime.options"
                :value="item.value"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
          </span>
        </div>
        <button class="btn-bk" @click="submitMyData">
          <img src="../assets/images/image28.svg" alt="" class="emoji" />
          저장하고 케미 보기
        </button>
      </div>
      <Modal v-if="showModal" @close="showModal = false">
        <template #body>
          <img src="../assets/images/image25.svg" alt="" class="emoji" />
          이름을 입력해주세요
        </template>
      </Modal>
      <Modal v-if="showModalBirth" @close="showModalBirth = false">
        <template #body>
          <img src="../assets/images/image25.svg" alt="" class="emoji" />
          생년월일을 입력해주세요
        </template>
      </Modal>
    </div>

    <div class="spinner" v-if="isLoading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
import formInput from '@/components/form-input';
import Modal from '@/components/modal';
import { postApi, postNewMember } from '@/api';

export default {
  name: 'survey',
  components: {
    formInput,
    Modal,
  },
  data() {
    return {
      isLoading: false,
      groupId: '',
      showModal: false,
      showModalBirth: false,
      form: {
        name: {
          value: '',
          placeholder: '이름을 입력하세요',
          maxlength: 5,
        },
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        getYear: () => {
          const years = [];
          for (let i = 2021; i >= 1920; i--) {
            years.push(i);
          }
          return years;
        },
        getMonth: () => {
          const months = [];
          for (let i = 1; i <= 12; i++) {
            if (i < 10) {
              i = '0' + i;
            }
            months.push(i);
          }
          return months;
        },
        getDay: () => {
          const days = [];
          let endDay = 31;
          if (this.form.birthMonth === '02') {
            this.getFebruary.forEach((el) => {
              if (this.form.birthYear === el) {
                endDay = 29;
              } else {
                endDay = 28;
              }
            });
          } else if (
            this.form.birthMonth === '04' ||
            this.form.birthMonth === '06' ||
            this.form.birthMonth === '09' ||
            this.form.birthMonth === 11
          ) {
            endDay = 30;
          }
          for (let i = 1; i <= endDay; i++) {
            if (i < 10) {
              i = '0' + i;
            }
            days.push(i);
          }
          return days;
        },
        birthTime: {
          chkValue: 0,
          options: [
            {
              value: 0,
              label: '태어난 시간 모름',
            },
            {
              value: 1,
              label: '00:30 ~ 01:29 자',
            },
            {
              value: 2,
              label: '01:30 ~ 03:29 축',
            },
            {
              value: 3,
              label: '03:30 ~ 05:29 인',
            },
            {
              value: 4,
              label: '05:30 ~ 07:29 묘',
            },
            {
              value: 5,
              label: '07:30 ~ 09:29 진',
            },
            {
              value: 6,
              label: '09:30 ~ 11:29 사',
            },
            {
              value: 7,
              label: '11:30 ~ 13:29 오',
            },
            {
              value: 8,
              label: '13:30 ~ 15:29 미',
            },
            {
              value: 9,
              label: '15:30 ~ 17:29 신',
            },
            {
              value: 10,
              label: '17:30 ~ 19:29 유',
            },
            {
              value: 11,
              label: '19:30 ~ 21:29 술',
            },
            {
              value: 12,
              label: '21:30 ~ 23:30 해',
            },
            {
              value: 13,
              label: '23:31 ~ 23:59 자',
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.groupId = this.$route.params.groupId;
  },
  computed: {
    getFebruary() {
      let feb = [];
      let sum = 1920;
      const endYear = this.form.getYear().length;
      for (let i = 0; i <= endYear; i += 4) {
        feb.push(sum + i);
      }
      return feb;
    },
  },
  methods: {
    async submitMyData() {
      if (this.form.name.value === '') {
        this.showModal = !this.showModal;
        return false;
      }
      if (
        (this.form.birthYear === '' ||
          this.form.birthMonth === '' ||
          this.form.birthDay === '') &&
        this.form.name.value !== ''
      ) {
        this.showModalBirth = !this.showModalBirth;
        return false;
      }

      if (this.groupId) {
        // eslint-disable-next-line
        fbq('track', 'Lead');
        try {
          this.isLoading = true;
          const { data: response } = await postNewMember({
            userName: this.form.name.value,
            birthYear: this.form.birthYear,
            birthMonth: this.form.birthMonth,
            birthDay: this.form.birthDay,
            birthTime: this.form.birthTime.chkValue,
            groupId: this.groupId,
          });
          if (response.code === 'S0000') {
            await this.$router.push(`/result/${this.groupId}`);
          } else if (response.status === 500) {
            await this.$router.push('/500');
          } else {
            await this.$router.push('/404');
          }
        } catch (e) {
          console.error('error', e);
          await this.$router.push('/500');
        } finally {
          this.isLoading = false;
        }
      } else {
        // eslint-disable-next-line
        fbq('track', 'Lead');
        try {
          this.isLoading = true;
          const { data: response } = await postApi({
            userName: this.form.name.value,
            birthYear: this.form.birthYear,
            birthMonth: this.form.birthMonth,
            birthDay: this.form.birthDay,
            birthTime: this.form.birthTime.chkValue,
          });
          if (response.code === 'S0000') {
            await this.$router.push(
              `/result/${response.data.hostUser.groupId}`
            );
          } else if (response.status === 500) {
            await this.$router.push('/500');
          } else {
            await this.$router.push('/404');
          }
        } catch (e) {
          console.error('error', e);
          await this.$router.push('/500');
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  width: 100%;
  margin-top: 60px;
}
.item {
  & + .item {
    margin-top: 30px;
  }
  .birth-area {
    display: flex;
    .form-select {
      flex: 1 1 33%;
      & + .form-select {
        margin-left: 12px;
      }
    }
  }
}

.emoji {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.form-select {
  position: relative;
  display: flex;
  margin-top: 12px;
  select {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 60px;
    padding: 15px 30px 15px 15px;
    border-radius: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    outline: none !important;
    background-color: #f5f5f5;
    color: #222;
    border: none;
    appearance: none;
    font-family: 'Noto Sans KR', sans-serif;
    background-image: url('../assets/images/ic_common_arrow_down_bk.svg');
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 30px auto;
    &.placeholderStyle {
      color: #888;
    }
  }
}
</style>
