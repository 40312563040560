/* eslint-disable */
const share = {
  checkAlreadyLoaded(src) {
    const loadedScripts = [...document.getElementsByTagName('script')];
    const result = loadedScripts.filter((script) => {
      return script.src === src;
    });

    return !!result.length;
  },
  loadScript(src) {
    return new Promise(function (resolve, reject) {
      if (share.checkAlreadyLoaded(src)) {
        return resolve(true);
      }

      const s = document.createElement('script');
      let r = false;
      s.type = 'text/javascript';
      s.src = src;
      s.async = true;
      s.onerror = function (err) {
        reject(err, s);
      };
      s.onload = s.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState == 'complete')) {
          r = true;
          resolve(true);
        }
      };
      const t = document.getElementsByTagName('script')[0];
      t.parentElement.insertBefore(s, t);
    });
  },
  kakao: {
    init() {
      Kakao.init('51eaf077b39041ae20b6dacee1db66e5');
    },
  },
};

export { share };
