<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body">default body</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('close')">
                확인
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 85vw;
  max-width: 360px;
  height: 160px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border: 1px solid #222;
  border-radius: 30px;
  box-shadow: 4px 6px 16px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 18px;
  line-height: 30px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.modal-default-button {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
}
</style>
