<template>
  <div class="container">
    <div class="contents">
      <h2 class="title">우리 케미는... 🤔</h2>
      <div class="result-box" :class="`position-ty${userData.length}`">
        <div class="cont-row">
          <span class="item host" :style="`background:${activeHostColor}`">
            <span class="image" :class="userChungan(hostUserData)"></span>
            <span class="name">
              {{ hostUserData.userName }}
              <i class="state">
                <img src="../assets/images/image15.svg" alt="이모지" />
              </i>
            </span>
          </span>
          <span
            v-for="user in userData"
            :key="user.id"
            @click="changeHost(user)"
            :class="[{ item: true }, userColor(user)]"
          >
            <span class="image" :class="userChungan(user)"></span>
            <span class="name">
              {{ user.userName }}
              <i class="state"></i>
            </span>
          </span>
          <template v-if="userData.length === 0">
            <span class="item blank">
              <span class="image">
                <img src="../assets/images/image21.svg" alt="이모지" />
              </span>
              <span class="name">
                ?
                <i class="state">?</i>
              </span>
            </span>
          </template>
        </div>
        <span class="host-gunghab">
          <span
            :style="`color:${activeHostColor}`"
            :class="{
              q: true,
              chColor:
                hostUserData.chungan === '경' || hostUserData.chungan === '신',
            }"
          >
            <strong>
              {{ hostUserData.userName }}
            </strong>
            님과 케미는?
          </span>
          <span class="text">친구들을 눌러 케미를 확인하세요</span>
        </span>
      </div>
      <p class="desc-ty2">
        <img src="../assets/images/image27.svg" alt="" class="emoji" />️ 한
        그룹에 최대 10명까지 등록할 수 있어요
      </p>

      <!-- <p class="desc-ty2 bk">
        이 테스트는 <br />
        1:1 실시간 영상/음성/문자 운세 상담 서비스<br />
        <span class="fc-orange fc-bold">출장도사</span> 에서 만든
        테스트입니다.<br />
        자세한 케미가 궁금하다면,<br />출장도사에 있는 660명의 용한
        신점/사주/타로 상담사를 만나보세요.
      </p> -->

      <div class="banner-area">
        <!-- <a
          href="https://qi3yfujvoki0qgrq8ipyaa.adtouch.adbrix.io/api/v1/click/k3G2vwRDL0W8I7Ej1eaT3A"
          target="_blank"
        > -->
        <!-- <img src="../assets/images/banner_img@2x.png" alt="banner" /> -->
        <a
          href="https://chooldo.sng.link/A256t/7579?_dl=chooldo.sng.link&_smtype=3"
          target="_blank"
        >
          <img src="../assets/images/banner_img.png" alt="banner" />
        </a>
      </div>

      <div class="banner-area">
        <!-- <a
          href="https://qi3yfujvoki0qgrq8ipyaa.adtouch.adbrix.io/api/v1/click/k3G2vwRDL0W8I7Ej1eaT3A"
          target="_blank"
        > -->
        <!-- <img src="../assets/images/banner_img@2x.png" alt="banner" /> -->
        <a
          href="https://chooldo.sng.link/A256t/7579?_dl=chooldo.sng.link&_smtype=3"
          target="_blank"
        >
          <img src="../assets/images/img_banner_2.png" alt="banner" />
        </a>
      </div>
      <h3 class="sub-title-ty2">👥 이 그룹과 케미 보기</h3>
      <template v-if="this.userData.length >= 9">
        <span class="maxNum fc-red">10명이 가득 찼어요</span>
      </template>
      <template v-else>
        <button @click="newMem" class="btn-gray fc-red">
          생년월일시 추가하기
        </button>
      </template>

      <h3 class="sub-title-ty2">👥 친구 초대하고 케미 보기</h3>
      <button class="btn-gray" @click="sendKakao">카카오톡 공유하기</button>
      <button class="btn-gray" @click="copyEvt">공유링크 복사하기</button>
      <h3 class="sub-title-ty2">👥 새로운 사람들과의 케미를 보고싶다면?</h3>
      <router-link to="/" class="btn-gray"> 새로운 그룹 만들기 </router-link>

    
      <h3 class="sub-title-ty2" style="">🔮 다른 테스트도 있어요!</h3>
      <div class = 'another-test'>
      <a href="http://abit.ly/pvo1cz"> <img class = 'another-test-img' src="../assets/images/img_tarot.png" alt="page" /> </a>
      </div>
      <div class = 'another-test'>
      <a href="https://abit.ly/ykpe79"><img class = 'another-test-img' src="../assets/images/img_face.png" alt= "page"/></a>
      </div>
      <div class = 'another-test'>
      <a href="https://abit.ly/sa8pqd"><img class = 'another-test-img' src="../assets/images/btn_gpt.png" alt= "page"/></a>
      </div>
      <Modal v-if="showModal" @close="showModal = false">
        <template #body>
          <img src="../assets/images/image26.svg" alt="" class="emoji" />
          링크가 복사되었어요!
        </template>
      </Modal>
    </div>
    <div class="spinner" v-if="isLoading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
import { getApi } from '@/api';
import Modal from '@/components/modal';
import { share } from '@/utils/share.js';

export default {
  name: 'result',
  components: {
    Modal,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      groupId: '',
      standUser: '',
      hostUserData: '',
      userData: [],
    };
  },
  async created() {
    await this.getData();

    const loadedKakaoApi = await share.loadScript(
      'https://developers.kakao.com/sdk/js/kakao.js'
    );

    if (loadedKakaoApi) {
      if (!window.Kakao.isInitialized()) {
        share.kakao.init();
      }
    }
  },
  computed: {
    activeHostColor() {
      let activeColor = '';
      switch (this.hostUserData.chungan) {
        case '갑':
        case '을':
          activeColor = '#19AF00';
          break;
        case '병':
        case '정':
          activeColor = '#FF4C13';
          break;
        case '무':
        case '기':
          activeColor = '#FBA705';
          break;
        case '경':
        case '신':
          activeColor = '#DDDDDD';
          break;
        default:
          activeColor = '#222222';
      }
      return activeColor;
    },
  },
  methods: {
    async getData(userSeq) {
      try {
        this.isLoading = true;
        const response = await getApi({
          groupId: this.$route.params.groupId,
          standUser: userSeq ? userSeq : 0,
        });
        if (response.data.code === 'S0000') {
          this.userData = response.data.data.list;
          this.hostUserData = response.data.data.hostUser;
          this.groupId = response.data.data.hostUser.groupId;
        } else {
          await this.$router.push('/404');
        }
      } catch (e) {
        console.error('error', e);
        await this.$router.push('/500');
      } finally {
        setTimeout(async () => {
          this.isLoading = false;
        }, 500);
      }
    },
    changeHost(user) {
      this.getData(user.userSeq);
    },
    userColor(user) {
      let resultPointColor = 'empty';
      if (0 <= user.resultPoint || user.resultPoint >= 22) {
        resultPointColor = 'worst';
      }

      if (23 <= user.resultPoint || user.resultPoint >= 41) {
        resultPointColor = 'bad';
      }

      if (42 <= user.resultPoint || user.resultPoint >= 54) {
        resultPointColor = 'average';
      }

      if (55 <= user.resultPoint || user.resultPoint >= 78) {
        resultPointColor = 'good';
      }

      if (79 <= user.resultPoint || user.resultPoint >= 100) {
        resultPointColor = 'best';
      }
      return resultPointColor;
    },
    userChungan(user) {
      let resultImg = '';
      switch (user.chungan) {
        case '갑':
          resultImg = 'image4';
          break;
        case '을':
          resultImg = 'image9';
          break;
        case '병':
          resultImg = 'image6';
          break;
        case '정':
          resultImg = 'image7';
          break;
        case '무':
          resultImg = 'image8';
          break;
        case '기':
          resultImg = 'image10';
          break;
        case '경':
          resultImg = 'image11';
          break;
        case '신':
          resultImg = 'image12';
          break;
        case '임':
          resultImg = 'image13';
          break;
        default:
          resultImg = 'image14';
      }
      return resultImg;
    },
    copyEvt() {
      const dummy = document.createElement('input');
      const currentUrl = window.document.location.href;
      document.body.appendChild(dummy);
      dummy.value = currentUrl;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.showModal = !this.showModal;
    },
    sendKakao() {
      window.Kakao.Link.sendCustom({
        templateId: 73015,
        templateArgs: {
          key: `${this.groupId}`,
        },
      });
    },
    newMem() {
      // eslint-disable-next-line
      fbq('track', 'Lead');
      this.$router.push(`/newMember/${this.groupId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.result-box {
  position: relative;
  width: calc(100% + 60px);
  min-height: 600px;
  margin-top: 40px;
  margin-left: -30px;
  padding: 0;
  background: #f5f5f5;
  overflow-x: auto;
  box-sizing: border-box;
  .host-gunghab {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    text-align: center;
    .q {
      display: block;
      font-size: 16px;
      line-height: 28px;
      &.chColor {
        color: #666 !important;
      }
    }
    .text {
      display: block;
      font-size: 14px;
      line-height: 26px;
      color: #888;
    }
  }
  .cont-row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .item {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 88px;
      padding-top: 15px;
      border-radius: 44px;
      background: #ffffff;
      box-sizing: border-box;
      cursor: pointer;
      .image {
        position: absolute;
        top: -19px;
        left: 50%;
        width: 36px;
        height: 36px;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-position: center;
        &.image4 {
          background-image: url('../assets/images/image4.svg');
        }
        &.image9 {
          background-image: url('../assets/images/image9.svg');
        }
        &.image6 {
          background-image: url('../assets/images/image6.svg');
        }
        &.image7 {
          background-image: url('../assets/images/image7.svg');
        }
        &.image8 {
          background-image: url('../assets/images/image8.svg');
        }
        &.image10 {
          background-image: url('../assets/images/image10.svg');
        }
        &.image11 {
          background-image: url('../assets/images/image11.svg');
        }
        &.image12 {
          background-image: url('../assets/images/image12.svg');
        }
        &.image13 {
          background-image: url('../assets/images/image13.svg');
        }
        &.image14 {
          background-image: url('../assets/images/image14.svg');
        }
      }
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        line-height: 24px;
        color: #222;
        margin-top: -13px;
      }
      &.empty {
        box-shadow: 0 0 20px rgba(34, 34, 34, 0.3);
        .state {
          &::after {
            content: '?';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #8f00ff;
          }
        }
      }
      &.worst {
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
        .state {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 2px;
            background: url('../assets/images/image18.svg') no-repeat center;
            vertical-align: middle;
          }
          &::after {
            content: '개나쁨';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #ff0000;
          }
        }
      }
      &.bad {
        box-shadow: 0 0 20px rgba(255, 122, 0, 0.3);
        .state {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 2px;
            background: url('../assets/images/image20.svg') no-repeat center;
            vertical-align: middle;
          }
          &::after {
            content: '나쁨';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #ffb800;
          }
        }
      }
      &.average {
        box-shadow: 0 0 20px rgba(99, 206, 60, 0.3);
        .state {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 2px;
            background: url('../assets/images/image16.svg') no-repeat center;
            vertical-align: middle;
          }
          &::after {
            content: '보통';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #63ce3c;
          }
        }
      }
      &.good {
        box-shadow: 0 0 20px rgba(0, 163, 255, 0.3);
        .state {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 2px;
            background: url('../assets/images/image19.svg') no-repeat center;
            vertical-align: middle;
          }
          &::after {
            content: '좋음';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #00a3ff;
          }
        }
      }
      &.best {
        box-shadow: 0 0 20px rgba(143, 0, 255, 0.3);
        .state {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 2px;
            background: url('../assets/images/image17.svg') no-repeat center;
            vertical-align: middle;
          }
          &::after {
            content: '개좋음';
            display: inline-block;
            font-size: 14px;
            line-height: 26px;
            font-weight: 700;
            color: #8f00ff;
          }
        }
      }
      &.host {
        font-weight: 700;
        cursor: default;
        animation: bounceIt 2s ease infinite;
        .name {
          color: #ffffff;
        }
        .image11,
        .image12 {
          & + .name {
            color: #666;
          }
        }
      }
    }
  }
  /*length check position style*/
  &[class*='position'] {
    .host,
    .blank,
    .item {
      position: absolute;
    }
    &[class*='ty0'] {
      min-height: 420px;
      .host {
        top: -10px;
        left: -148px;
      }
      .blank {
        background: #fff;
        top: -10px;
        left: 70px;
        box-shadow: 0 0 20px rgba(34, 34, 34, 0.3);
        .name {
          color: #222;
          text-align: center;
          .state {
            margin: 0;
            font-weight: bold;
            color: #222;
          }
        }
      }
    }
    &[class*='ty1'] {
      min-height: 420px;
      .item {
        &:nth-child(1) {
          top: -10px;
          left: -148px;
        }
        &:nth-child(2) {
          top: -10px;
          left: 70px;
        }
      }
    }
    &[class*='ty2'] {
      min-height: 454px;
      .item {
        &:nth-child(1) {
          top: -60px;
          left: -38px;
        }
        &:nth-child(2) {
          top: 80px;
          left: -130px;
        }
        &:nth-child(3) {
          top: 80px;
          left: 50px;
        }
      }
    }
    &[class*='ty3'] {
      min-height: 464px;
      .item {
        &:nth-child(1) {
          top: -80px;
          left: -38px;
        }
        &:nth-child(2) {
          top: 90px;
          left: -150px;
        }
        &:nth-child(3) {
          top: 90px;
          left: -40px;
        }
        &:nth-child(4) {
          top: 90px;
          left: 70px;
        }
      }
    }
    &[class*='ty4'] {
      .item {
        &:nth-child(1) {
          top: -130px;
          left: -38px;
        }
        &:nth-child(2) {
          top: -10px;
          left: 40px;
        }
        &:nth-child(3) {
          top: 140px;
          left: 40px;
        }
        &:nth-child(4) {
          top: 140px;
          left: -120px;
        }
        &:nth-child(5) {
          top: -10px;
          left: -120px;
        }
      }
    }
    &[class*='ty5'] {
      .item {
        &:nth-child(1) {
          top: -130px;
          left: -38px;
        }
        &:nth-child(2) {
          top: -10px;
          left: -110px;
        }
        &:nth-child(3) {
          top: -10px;
          left: 30px;
        }
        &:nth-child(4) {
          top: 140px;
          left: -160px;
        }
        &:nth-child(5) {
          top: 140px;
          left: -40px;
        }
        &:nth-child(6) {
          top: 140px;
          left: 80px;
        }
      }
    }
    &[class*='ty6'] {
      .item {
        &:nth-child(1) {
          top: -130px;
          left: -38px;
        }
        &:nth-child(2) {
          top: -10px;
          left: -160px;
        }
        &:nth-child(3) {
          top: -10px;
          left: -38px;
        }
        &:nth-child(4) {
          top: -10px;
          left: 80px;
        }
        &:nth-child(5) {
          top: 140px;
          left: -160px;
        }
        &:nth-child(6) {
          top: 140px;
          left: -38px;
        }
        &:nth-child(7) {
          top: 140px;
          left: 80px;
        }
      }
    }
    &[class*='ty7'] {
      .item {
        &:nth-child(1) {
          top: -130px;
          left: -38px;
        }
        &:nth-child(2) {
          top: -10px;
          left: -140px;
        }
        &:nth-child(3) {
          top: -10px;
          left: -38px;
        }
        &:nth-child(4) {
          top: -10px;
          left: 60px;
        }
        &:nth-child(5) {
          top: 140px;
          left: -185px;
        }
        &:nth-child(6) {
          top: 140px;
          left: -92px;
        }
        &:nth-child(7) {
          top: 140px;
          left: 3px;
        }
        &:nth-child(8) {
          top: 140px;
          left: 95px;
        }
      }
    }
    &[class*='ty8'] {
      .item {
        &:nth-child(1) {
          top: -130px;
          left: -38px;
        }
        &:nth-child(2) {
          top: -10px;
          left: -185px;
        }
        &:nth-child(3) {
          top: -10px;
          left: -92px;
        }
        &:nth-child(4) {
          top: -10px;
          left: 3px;
        }
        &:nth-child(5) {
          top: -10px;
          left: 95px;
        }
        &:nth-child(6) {
          top: 140px;
          left: -185px;
        }
        &:nth-child(7) {
          top: 140px;
          left: -92px;
        }
        &:nth-child(8) {
          top: 140px;
          left: 3px;
        }
        &:nth-child(9) {
          top: 140px;
          left: 95px;
        }
      }
    }
    &[class*='ty9'] {
      min-height: 740px;
      .item {
        &:nth-child(1) {
          top: -225px;
          left: -45px;
        }
        &:nth-child(2) {
          top: -75px;
          left: -165px;
        }
        &:nth-child(3) {
          top: -75px;
          left: -45px;
        }
        &:nth-child(4) {
          top: -75px;
          left: 85px;
        }
        &:nth-child(5) {
          top: 65px;
          left: -165px;
        }
        &:nth-child(6) {
          top: 65px;
          left: -45px;
        }
        &:nth-child(7) {
          top: 65px;
          left: 85px;
        }
        &:nth-child(8) {
          top: 205px;
          left: -165px;
        }
        &:nth-child(9) {
          top: 205px;
          left: -45px;
        }
        &:nth-child(10) {
          top: 205px;
          left: 85px;
        }
      }
    }
  }
}
.desc-ty2 {
  &.bk {
    margin-top: 10px;
    color: #222;
  }
}
.sub-title-ty2 {
  margin-top: 20px;
}
[class^='btn'] {
  & + .sub-title-ty2 {
    margin-top: 24px;
  }
}
.maxNum {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 12px;
  font-size: 16px;
  line-height: 28px;
  background: #fafafa;
  border: 1px solid #eee;
}

.banner-area {
  margin-top: 20px;
  a {
    display: block;
    width: 100%;
  }
}

.emoji {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}
.another-test{
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}
.another-test-img {
  width: 100%;

  //background: #FF9900;
  border-radius: 5px;
}
.another-test-font {
  /* 타로로 보는 연애관 테스트 */
  width: 94px;
  height: 56px;
  display: flex;
  text-align: center;
  /* noto sans_text style/16px_28px_regular

  1rem_1.75rem
  */
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  
  /* or 175% */


    word-break: keep-all;
  color: #222222;
  margin-bottom: 60px;

}
</style>
