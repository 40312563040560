<template>
  <div class="error">404</div>
</template>

<script>
export default {
  name: 'error-404',
};
</script>

<style scoped></style>
